<template>
  <div class="panel-detail panel-fixed">
    <div class="panel-header">
      <h1 class="page-header">{{ `${$t('Create')} ${$t(title.toLowerCase())}`}}</h1>
      <ol class="breadcrumb pull-right">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard'}">
              {{"Dashboard"}}
            </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: routeNameList }">
              {{ pluralTitle | translate }}
            </router-link>
        </li>
        <li class="breadcrumb-item">
              {{ "Create" | translate }}
        </li>
      </ol>
    </div>
    <div class="row">
      <!-- begin col-12 -->
      <div class="col-12">
        <!-- begin panel -->
         <panel :title="$t('New Organization')" noButton="true">
           <organization-form
            :router-name-list="routeNameList"
            :errors="errors"
            :isExtra="isExtra"
            :org-type-list="orgTypeChoices"
            :org-type="orgType"
            :org-group-list="groups"
            @search-group="onSearchGroup"
            :org-form-list="organizationform"
            :org-inv-type-list="typologyChoices"
            :org-employee-cat-list="employeeCategoryChoices"
            :operation="'create'"
            @submit="onCreate"
          />
         </panel>
      </div>
    </div>
    <duplicated-organization-modal
      v-if="showDuplicatedForm"
      v-on:close-modal="closeModal"
      :org-type="orgType"
      v-on:close-modal-and-go-to-detail="goToDetailPage"
      :orgFilters="orgFilters"
    ></duplicated-organization-modal>
  </div>
</template>

<script>
import PopulateSelectMixin from '@/components/PopulateSelectMixin'
import OrganizationForm from '../OrganizationForm'
import DuplicatedOrganizationModal from '../organization-modal/DuplicatedOrganizationModal'
import { vue } from '../../../../../main'

export default {
  name: 'OrganizationCreateMixin',
  mixins: [PopulateSelectMixin],
  components: {
    OrganizationForm,
    DuplicatedOrganizationModal
  },
  data () {
    return {
      routeNameList: '',
      routeNameDetail: '',
      title: '',
      pluralTitle: '',
      orgType: '',
      isExtra: false,
      form: {},
      showDuplicatedForm: false,
      orgFilters: undefined
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.cleanErrors()
      this.setGroups({})
      this.getOrganizationform({ filter__status: 1 })
      this.getTypologychoices()
      this.getOrgtypechoices()
      this.getEmployeecategorychoices()
    },
    // organization group
    onSearchGroup (filters) {
      if (filters) {
        this.getGroups(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setGroups({})
      }
    },
    /// //////////////
    onCreate (evt) {
      const formData = evt.data
      this.create(formData).then((response) => {
        const organizationId = this.detail.id
        setTimeout(() => {
          this.$router.push({ name: this.routeNameDetail, params: { id: organizationId } })
        }, 500)
      }).catch((error) => {
        this.orgFilters = {
          name: formData.name
        }
        if (formData.ssn) {
          this.orgFilters.ssn = formData.ssn
        }
        if (formData.vat_number) {
          this.orgFilters.vat_number = formData.vat_number
        }
        if ('duplicated_organization' in error.response.data) {
          this.showDuplicatedForm = true
        }
      })
    },
    closeModal () {
      this.orgFilters = false
      this.showDuplicatedForm = false
    },
    goToDetailPage (evt) {
      this.$router.push({ name: this.routeNameDetail, params: { id: evt.orgId } })
      this.closeModal()
    }
  }
}
</script>
